import React from 'react';
import Img from "gatsby-image";
import {Link} from "gatsby";

const Footer = ({imgBg, copyrightName, personalText, facebook, instagram, youtube}) => {
    return (
        <footer className="footer">
            <Img fluid={imgBg} className="footer__img"/>
            <div className="container">
                <div className="footer-wrapper">
                    <div className="footer__copyright">
                        © {new Date().getFullYear()} | {copyrightName}
                    </div>
                    <Link to='/personal-data/' className="footer__personal">
                        {personalText}
                    </Link>
                    <div className="footer-social">
                        {
                            youtube &&
                            <a href={youtube} className="footer-social__link" target="_blank" rel="noreferrer">
                                <svg width="22px" height="19px" viewBox="0 0 25 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1552.000000, -4778.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g transform="translate(1552.000000, 4778.000000)">
                                                <path d="M20.7692308,0 L4.23076923,0 C1.8941799,0 0,2.07938861 0,4.64444444 L0,14.3555556 C0,16.9206114 1.8941799,19 4.23076923,19 L20.7692308,19 C23.1058201,19 25,16.9206114 25,14.3555556 L25,4.64444444 C25,2.07938861 23.1058201,0 20.7692308,0 Z M13,11.75 L9,14 L9,5 L13,7.25 L17,9.5 L13,11.75 Z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        }
                        {
                            facebook &&
                            <a href={facebook} className="footer-social__link" target="_blank" rel="noreferrer">
                                <svg width="10px" height="19px" viewBox="0 0 10 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1614.000000, -4778.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g transform="translate(1552.000000, 4778.000000)">
                                                <g transform="translate(62.000000, 0.052632)">
                                                    <path d="M9.62348606,0.00394231832 L7.22493915,0 C4.53022691,0 2.78878523,1.83021726 2.78878523,4.66297011 L2.78878523,6.81291284 L0.377150213,6.81291284 C0.1687543,6.81291284 0,6.98598568 0,7.19946189 L0,10.3144926 C0,10.5279916 0.168946664,10.7007916 0.377150213,10.7007916 L2.78878523,10.7007916 L2.78878523,18.5609937 C2.78878523,18.7744926 2.95753953,18.9473684 3.16592804,18.9473684 L6.31239781,18.9473684 C6.52081592,18.9473684 6.68957761,18.7742653 6.68957761,18.5609937 L6.68957761,10.7007916 L9.50932592,10.7007916 C9.71774402,10.7007916 9.88650572,10.5279916 9.88650572,10.3144926 L9.88761551,7.19946189 C9.88761551,7.09695663 9.84781113,6.99879411 9.77722864,6.926256 C9.70657216,6.85371789 9.61039057,6.81291284 9.51028773,6.81291284 L6.68957761,6.81291284 L6.68957761,4.99038063 C6.68957761,4.11439832 6.89333462,3.66970358 8.00726541,3.66970358 L9.62304215,3.66911242 C9.8312383,3.66911242 10,3.49604716 10,3.282768 L10,0.390289263 C10,0.177207158 9.83146026,0.00433655242 9.62348606,0.00394231832 Z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        }
                        {
                            instagram &&
                            <a href={instagram} className="footer-social__link" target="_blank" rel="noreferrer">
                                <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1661.000000, -4778.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g id="social" transform="translate(1552.000000, 4778.000000)">
                                                <g transform="translate(109.000000, 0.052632)">
                                                    <g>
                                                        <path d="M13.0325443,4.13891144e-13 L4.96724279,4.13891144e-13 C2.22830678,4.13891144e-13 0,2.34569815 0,5.22878869 L0,13.7185797 C0,16.6017823 2.22830678,18.9473684 4.96724279,18.9473684 L13.0325443,18.9473684 C15.7716932,18.9473684 18,16.6016703 18,13.7185797 L18,5.22878869 C18,2.34569815 15.7716932,4.13891144e-13 13.0325443,4.13891144e-13 Z M16.4030592,13.7185797 C16.4030592,15.6748225 14.8910814,17.2662661 13.0326507,17.2662661 L4.96724279,17.2662661 C3.10891862,17.2662661 1.59704724,15.6748225 1.59704724,13.7185797 L1.59704724,5.22878869 C1.59704724,3.27265799 3.10891862,1.68110235 4.96724279,1.68110235 L13.0325443,1.68110235 C14.8909749,1.68110235 16.4030592,3.27265799 16.4030592,5.22878869 L16.4030592,13.7185797 L16.4030592,13.7185797 Z"></path>
                                                        <path d="M9,4.54736842 C6.41940913,4.54736842 4.32,6.7573235 4.32,9.4737973 C4.32,12.190158 6.41940913,14.4 9,14.4 C11.5805909,14.4 13.68,12.190158 13.68,9.4737973 C13.68,6.7573235 11.5805909,4.54736842 9,4.54736842 Z M9,12.7035751 C7.3080752,12.7035751 5.93145927,11.2546986 5.93145927,9.47368421 C5.93145927,7.69255677 7.30796777,6.24356718 9,6.24356718 C10.6920322,6.24356718 12.0685407,7.69255677 12.0685407,9.47368421 C12.0685407,11.2546986 10.6919248,12.7035751 9,12.7035751 Z"></path>
                                                        <path d="M13.68,3.03157895 C13.3016727,3.03157895 12.9300218,3.19280383 12.6628364,3.47529187 C12.3943418,3.75640191 12.24,4.1477512 12.24,4.54736842 C12.24,4.94574545 12.3944727,5.33695694 12.6628364,5.61944498 C12.9298909,5.90055502 13.3016727,6.06315789 13.68,6.06315789 C14.0596364,6.06315789 14.4301091,5.90055502 14.6984727,5.61944498 C14.9668364,5.33695694 15.12,4.94560766 15.12,4.54736842 C15.12,4.1477512 14.9668364,3.75640191 14.6984727,3.47529187 C14.4314182,3.19280383 14.0596364,3.03157895 13.68,3.03157895 Z"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        }

                    </div>
                </div>
            </div>
        </footer>
    );
};


export default Footer;