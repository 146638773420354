/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import {connect} from "react-redux";
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ languageSelect, description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `
  )

  const metaDescription = description || site.siteMetadata.description;

  return (
      <Helmet
          htmlAttributes={{
            lang: languageSelect,
          }}
          title={title}
          titleTemplate={`%s`}
          link={[
            {
              rel: 'alternate',
              hreflang: 'ru',
              href: 'https://135m.ru/'
            },
            {
              rel: 'alternate',
              hreflang: 'en',
              href: 'https://135m.ru/en/'
            }
          ]}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: site.siteMetadata.author,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `yandex-verification`,
              content: process.env.yandexVerification,
            },
          ].concat(
              keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords,
                  }
                  : []
          )
              .concat(meta)}
      />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};


let mapStateToProps = (state) => {
  return {
    languageSelect: state.languageSelect["languageSelect"],
  }
};

export default connect(mapStateToProps)(SEO);
