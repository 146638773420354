import React from 'react';

import PhotoGallery from '../../modules/PhotoGallery/containers/PhotoGalleryDetail'
import YandexMap from '../../modules/YandexMap/containers/YandexMap'


const TourDetail = ({description, photogallery, subtitleRoute, subtitlePhoto, yandexRoutes}) => {
    return (
        <div className="tour-detail">
            <div className="tour-detail-content">
                {description}
            </div>
            {
                yandexRoutes &&
                <div className="tour-detail-map">
                    <h2 className="subtitle subtitle_lvl_two subtitle_color_black tour-detail__subtitle">
                        {subtitleRoute}
                    </h2>
                    <YandexMap yandexRoutes={yandexRoutes}/>
                </div>
            }
            {
                photogallery &&
                <div className="tour-detail-photo">
                    <h2 className="subtitle subtitle_lvl_two subtitle_color_black tour-detail__subtitle">
                        {subtitlePhoto}
                    </h2>
                    <PhotoGallery items={photogallery}/>
                </div>
            }

        </div>
    );
};


export default TourDetail;