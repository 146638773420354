import React from 'react';
import {connect} from "react-redux";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import {LayoutPages, Preloader, Seo} from '../components';

const TourDetail = loadable(() => pMinDelay(import('../components/TourDetail/TourDetail'), 1000), {
    fallback: <Preloader/>,
});

const TourDetailTemplate = ({pageContext, languageSelect}) => {

    const Bold = ({ children }) => <div className="tour-detail__text tour-detail__text_weight_bold">{children}</div>;
    const Text = ({ children }) => children;
    const List = ({ children }) => <ul className="tour-detail-list">{children}</ul>;

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>
        },
    };

    const photoGallery = [];

    pageContext.photogallery &&
    pageContext.photogallery.map(item => {
        return photoGallery.push({
                id: item.id,
                img: {
                    title: item.title,
                    fluid: item.fluid
                }
            }
        );
    });



    const breadcrumbs = [
        {
            pathname: "/", crumbLabel: `${languageSelect === 'ru' ? 'Главная' : 'Home'}`, active: false
        },
        {
            pathname: "/tours/", crumbLabel: `${pageContext.breadCrumbSubtitle["subtitle_" + languageSelect]}`, active: false
        },
        {
            pathname: "", crumbLabel: `${pageContext["subtitle_" + languageSelect]}`, active: true
        }
    ];

    return (
        <LayoutPages titlePage={pageContext["subtitle_" + languageSelect]}
                     breadcrumbs={breadcrumbs}
                     buttonDoc={pageContext["doc_" + languageSelect] && pageContext["doc_" + languageSelect].file.url}
                     buttonDocName={languageSelect === 'en' ? 'Download presentation' : 'Скачать презентацию'}
                     className="section-tour-detail"
        >
            <Seo title={`${pageContext["subtitle_" + languageSelect]}`}
                 description={`${pageContext.seo["description_" + languageSelect]}`}
                 keywords={`${pageContext.seo["keywords_" + languageSelect]}`}
            />
            <div className="container">
                <TourDetail description={documentToReactComponents(pageContext["description_" + languageSelect], options)}
                            photogallery={photoGallery}
                            subtitleRoute={languageSelect === 'ru' ? 'Маршрут' : 'Route'}
                            subtitlePhoto={languageSelect === 'ru' ? 'Фотографии' : 'Photo'}
                            yandexRoutes={pageContext.route}
                />
            </div>
        </LayoutPages>
    )
};




let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(TourDetailTemplate);