import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import classNames from 'classnames';
import Burger from '@animated-burgers/burger-squeeze';
import '@animated-burgers/burger-squeeze/dist/styles.css';

import {isMenuOpen} from "../../../redux/reducers/menuHamburger";
import MenuItem from "../components/MenuItem";
import Language from "../../Language/containers/LunguageContainer";


const MenuHamburgerContainer = ({statusMenu, languageSelect, isMenuOpen}) => {

    const menuOpen = () => {
        isMenuOpen(!statusMenu);
    };

    useEffect(() => {
        statusMenu ?
            document.body.classList.add('body-menu-open')
            :
            document.body.classList.remove('body-menu-open');
    });


    return (
        <StaticQuery
            query={graphql`
          query {
             contentfulHomeScreen {
                menu{
                    id
                    subtitle_ru
                    subtitle_en
                    path
                }
              }
          }
        `}
            render={data => {
               const menuElements = data.contentfulHomeScreen.menu.map(item => {

                    return (
                        <MenuItem key={item.id} name={item["subtitle_" + languageSelect]} path={item.path}/>
                    );
                });

                return (

                    <div className="menu-hamburger">
                        <Burger isOpen={statusMenu}  onClick={menuOpen}/>
                        <div className={classNames("menu-hamburger-slide", {
                            "menu-hamburger-slide_active": statusMenu
                        })}>

                            <div className="menu-hamburger-wrapper">
                                <div className="menu-hamburger-items" onClick={() => isMenuOpen(false)}>
                                    {menuElements}
                                </div>
                                <div className="menu-hamburger-language">
                                    <Language/>
                                </div>
                            </div>


                        </div>
                    </div>
                )
            }}
        />

    );
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
        statusMenu: state.menuHamburger["isOpen"],
    }
};

export default connect(mapStateToProps, {isMenuOpen})(MenuHamburgerContainer);