import React from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';
import Responsive from "react-responsive"

import Language from "../../Language/containers/LunguageContainer";
import Menu from "../../Menu/containers/MenuContainer";
import MenuHamburger from '../../Menu/containers/MenuHamburgerContainer';


const MenuAdp = props => <Responsive {...props} maxWidth={1023}/>;
const NotMenuAdp = props => <Responsive {...props} minWidth={1024}/>;

const HeaderContainer = ({languageSelect, className}) => {
    return (
        <>
            <header className={classNames("header", className)}>
                <div className="container">
                    <div className="logo">
                        {
                            languageSelect === 'ru' ?
                                <svg width="125px" height="76px" viewBox="0 0 145 96" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" fill="none">
                                        <g transform="translate(-238.000000, -60.000000)" fill="#FFFFFF">
                                            <g>
                                                <g transform="translate(238.000000, 60.000000)">
                                                    <g opacity="0.25">
                                                        <polygon
                                                            points="18.2 0 32 0 32 68.91 16.23 68.91 16.23 16.64 4 20 0.19 6.5"></polygon>
                                                        <path
                                                            d="M75.4,28 C79.5649538,29.3517923 83.2475106,31.8844186 86,35.29 C88.6912636,38.7015006 90.1061105,42.9460413 90,47.29 C90,54.51 87.57,60.1566667 82.71,64.23 C77.85,68.3033333 71.9466667,70.3233333 65,70.29 C59.8830991,70.3729143 54.8376798,69.0813422 50.39,66.55 C46.0802541,64.0513707 42.7346809,60.1775491 40.89,55.55 L54.47,47.68 C56.1566667,52.52 59.6666667,54.9366667 65,54.93 C68.0933333,54.93 70.4266667,54.21 72,52.77 C73.5039667,51.3357633 74.3550024,49.3482085 74.3550024,47.27 C74.3550024,45.1917915 73.5039667,43.2042367 72,41.77 C70.4266667,40.33 68.0933333,39.61 65,39.61 L61.72,39.61 L55.72,30.61 L68.22,14.76 L43,14.76 L43,0 L87.3,0 L87.3,12.8 L75.4,28 Z"
                                                            id="Path"></path>
                                                        <path
                                                            d="M120.59,24.22 C127.343333,24.22 132.97,26.2366667 137.47,30.27 C141.97,34.3033333 144.216667,39.97 144.210015,47.27 C144.210015,54.49 141.783333,60.1366667 136.93,64.21 C132.076667,68.2833333 126.136667,70.3166825 119.11,70.31 C113.85338,70.4209571 108.659912,69.1484539 104.05,66.62 C99.6863162,64.1266196 96.3140312,60.2070004 94.5,55.52 L108.08,47.65 C109.793333,52.51 113.46,54.9366667 119.08,54.93 C122.16,54.93 124.493333,54.21 126.08,52.77 C127.635059,51.3629522 128.497226,49.3463582 128.44,47.25 C128.504174,45.1755763 127.682339,43.1718976 126.18,41.74 C124.666667,40.3 122.466667,39.58 119.58,39.58 L98,39.58 L100.9,0 L140.77,0 L140.77,14.77 L115.66,14.77 L115,24.22 L120.59,24.22 Z"
                                                            id="Path"></path>
                                                    </g>
                                                    <polygon
                                                        points="13.06 79.4 13.06 91.23 9.28 91.23 9.28 85.53 6.74 89.45 6.32 89.45 3.81 85.55 3.81 91.23 0 91.23 0 79.4 3.81 79.4 6.53 83.66 9.28 79.4"></polygon>
                                                    <path
                                                        d="M23.54,86.76 C24.0143856,87.766916 25.0870581,88.3498022 26.19,88.2 C26.9920231,88.2233157 27.7699637,87.9243849 28.35,87.37 L31.19,89.37 C29.9419426,90.8740011 28.0506183,91.6914694 26.1,91.57 C24.3225767,91.6517888 22.5865097,91.0178722 21.28,89.81 C20.080662,88.6410922 19.4244473,87.0241212 19.47,85.35 C19.4221681,83.6676317 20.0715597,82.0402567 21.2644913,80.8530057 C22.457423,79.6657546 24.0878792,79.0241383 25.77,79.08 C27.4136793,79.0292965 29.0030266,79.671567 30.15,80.85 C31.3016976,82.0395885 31.9222426,83.6450683 31.87,85.3 C31.8744626,85.7945772 31.8207606,86.2879641 31.71,86.77 L23.54,86.76 Z M23.54,84.04 L28,84.04 C27.7861837,83.0049338 26.8332837,82.292405 25.78,82.38 C24.7280953,82.2970518 23.7677875,82.9793758 23.5,84 L23.54,84.04 Z"
                                                    ></path>
                                                    <path
                                                        d="M45.57,79.07 C47.1025738,79.0345923 48.5703773,79.6877649 49.57,80.85 C51.7097065,83.4383829 51.7097065,87.1816171 49.57,89.77 C48.5714202,90.9347924 47.1039201,91.5914988 45.57,91.56 C44.2916552,91.6261062 43.0534943,91.1028358 42.21,90.14 L42.21,96 L38.29,96 L38.29,79.4 L42.21,79.4 L42.21,80.49 C43.0534943,79.5271642 44.2916552,79.0038938 45.57,79.07 Z M42.9,87.18 C43.9139359,88.1250437 45.4860641,88.1250437 46.5,87.18 C46.9705464,86.6744703 47.2159179,85.9996986 47.18,85.31 C47.2124905,84.620818 46.9675877,83.9473354 46.5,83.44 C45.4860641,82.4949563 43.9139359,82.4949563 42.9,83.44 C42.4297498,83.9463282 42.1813311,84.6195787 42.21,85.31 C42.1767667,86.0011315 42.4258179,86.6760966 42.9,87.18 L42.9,87.18 Z"
                                                    ></path>
                                                    <polygon
                                                        points="66 79.4 69 79.4 69 91.23 65.19 91.23 65.19 85.69 60.6 91.23 57.6 91.23 57.6 79.4 61.44 79.4 61.44 84.93"></polygon>
                                                    <path
                                                        d="M87.2,87.51 L88.74,87.51 L88.74,94.4 L85,94.4 L85,91.23 L78.8,91.23 L78.8,94.4 L75,94.4 L75,87.51 L76.42,87.51 C77.0783658,86.2833931 77.3895423,84.9003863 77.32,83.51 L77.32,79.4 L87.2,79.4 L87.2,87.51 Z M80.5,87.51 L83.36,87.51 L83.36,82.9 L81.16,82.9 L81.16,83.49 C81.1960349,84.8596689 80.9720808,86.223753 80.5,87.51 Z"
                                                    ></path>
                                                    <polygon
                                                        points="103.38 79.4 106.38 79.4 106.38 91.23 102.55 91.23 102.55 85.69 98 91.23 95 91.23 95 79.4 98.83 79.4 98.83 84.93"></polygon>
                                                    <path
                                                        d="M121.75,79.4 L125.67,79.4 L125.67,91.23 L121.75,91.23 L121.75,90.14 C120.906506,91.1028358 119.668345,91.6261062 118.39,91.56 C116.85608,91.5914988 115.38858,90.9347924 114.39,89.77 C112.269915,87.1745895 112.269915,83.4454105 114.39,80.85 C115.389623,79.6877649 116.857426,79.0345923 118.39,79.07 C119.668345,79.0038938 120.906506,79.5271642 121.75,80.49 L121.75,79.4 Z M117.46,87.18 C118.473936,88.1250437 120.046064,88.1250437 121.06,87.18 C121.534182,86.6760966 121.783233,86.0011315 121.75,85.31 C121.778669,84.6195787 121.53025,83.9463282 121.06,83.44 C120.046064,82.4949563 118.473936,82.4949563 117.46,83.44 C116.992412,83.9473354 116.74751,84.620818 116.78,85.31 C116.744082,85.9996986 116.989454,86.6744703 117.46,87.18 L117.46,87.18 Z"
                                                    ></path>
                                                    <polygon
                                                        points="140.08 79.4 144 79.4 144 91.23 140.07 91.23 140.07 87.16 136.6 87.16 136.6 91.23 132.67 91.23 132.67 79.4 136.6 79.4 136.6 83.47 140.07 83.47"></polygon>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            :

                                <svg width="125px" height="76px" viewBox="0 0 145 96" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-415.000000, -60.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g transform="translate(415.000000, 60.000000)">
                                                <g opacity="0.25">
                                                    <polygon points="18.1 0 31.89 0 31.89 68.91 16.14 68.91 16.14 16.64 3.93 20 0.09 6.5"></polygon>
                                                    <path d="M75.3,28 C79.480771,29.3575818 83.1749028,31.9048994 85.93,35.33 C88.6212636,38.7415006 90.0361105,42.9860413 89.93,47.33 C89.93,54.55 87.5,60.1966667 82.64,64.27 C77.78,68.3433333 71.8433333,70.3766667 64.83,70.37 C59.7097776,70.4542194 54.6607984,69.1626201 50.21,66.63 C45.9002541,64.1313707 42.5546809,60.2575491 40.71,55.63 L54.29,47.76 C55.9966667,52.62 59.51,55.0466667 64.83,55.04 C67.91,55.04 70.2433333,54.32 71.83,52.88 C73.3287169,51.4428842 74.1760641,49.4564041 74.1760641,47.38 C74.1760641,45.3035959 73.3287169,43.3171158 71.83,41.88 C70.25,40.44 67.9166667,39.72 64.83,39.72 L61.62,39.72 L55.62,30.72 L68.12,14.87 L42.91,14.87 L42.91,0 L87.21,0 L87.21,12.8 L75.3,28 Z"></path>
                                                    <path d="M120.49,24.22 C127.25,24.22 132.876667,26.2366667 137.37,30.27 C141.863333,34.3033333 144.11,39.97 144.11,47.27 C144.11,54.49 141.683333,60.1366667 136.83,64.21 C131.976667,68.2833333 126.033333,70.31 119,70.29 C113.763621,70.390422 108.592186,69.1182487 104,66.6 C99.6210653,64.1205111 96.2306412,60.2073967 94.4,55.52 L108,47.65 C109.7,52.51 113.366667,54.9366667 119,54.93 C122.08,54.93 124.42,54.21 126,52.77 C127.559568,51.3661729 128.422669,49.3473922 128.36,47.25 C128.424174,45.1755763 127.602339,43.1718976 126.1,41.74 C124.593333,40.3 122.393333,39.58 119.5,39.58 L97.94,39.58 L100.8,0 L140.67,0 L140.67,14.77 L115.57,14.77 L114.88,24.22 L120.49,24.22 Z"></path>
                                                </g>
                                                <path d="M14.59,79 C15.8865964,78.9334943 17.1519535,79.4120821 18.08,80.32 C18.9867166,81.3231239 19.4484884,82.6507178 19.36,84 L19.36,91.29 L15.12,91.29 L15.12,84.41 C15.12,83.3 14.59,82.74 13.54,82.74 C13.0459911,82.7063537 12.5647793,82.9062416 12.24,83.28 C11.9204093,83.6972856 11.7607771,84.2152034 11.79,84.74 L11.79,91.25 L7.57,91.25 L7.57,84.41 C7.57,83.3 7.04,82.74 5.97,82.74 C5.47334049,82.7078442 4.98972929,82.9071954 4.66,83.28 C4.34786634,83.7006175 4.19225801,84.2169542 4.22,84.74 L4.22,91.25 L0,91.25 L0,79.33 L4.22,79.33 L4.22,80.45 C5.06818888,79.4493895 6.34127502,78.9112044 7.65,79 C8.92295338,78.9123829 10.1512269,79.4868369 10.9,80.52 C11.8381561,79.4832417 13.1938081,78.9248159 14.59,79 Z"></path>
                                                <path d="M31.28,86.75 C31.7,87.75 32.65,88.2 34.13,88.2 C34.9766458,88.2215994 35.8007125,87.9255062 36.44,87.37 L39.49,89.37 C38.0987294,90.9148292 36.0740105,91.733568 34,91.59 C32.1092211,91.6810457 30.2554429,91.0440331 28.82,89.81 C27.5464161,88.6700766 26.8372188,87.0286869 26.88,85.32 C26.8461676,83.613421 27.5408019,81.9732112 28.79,80.81 C30.1080918,79.5766108 31.8664185,78.9244446 33.67,79 C35.413196,78.9429751 37.1063724,79.5878231 38.37,80.79 C39.5992822,81.9505998 40.2757828,83.5800205 40.23,85.27 C40.2330401,85.7689724 40.172554,86.2663025 40.05,86.75 L31.28,86.75 Z M31.23,84 L36.11,84 C35.8342301,82.9300772 34.8195055,82.2210437 33.72,82.33 C32.5945094,82.2078222 31.5440694,82.9123342 31.23,84 L31.23,84 Z"></path>
                                                <path d="M52.14,81.55 C52.3697644,80.7870905 52.8809582,80.140055 53.57,79.74 C54.3019625,79.2972598 55.1447471,79.0718236 56,79.09 L56,83.48 C55.0917298,83.3248778 54.1579802,83.5023967 53.37,83.98 C52.5680309,84.4770814 52.1092993,85.379125 52.18,86.32 L52.18,91.25 L47.93,91.25 L47.93,79.33 L52.14,79.33 L52.14,81.55 Z"></path>
                                                <path d="M67.28,77.68 C66.8169471,78.1217718 66.1999593,78.3656972 65.56,78.36 C64.9226945,78.3673557 64.308216,78.1230017 63.85,77.68 C63.3849409,77.2744125 63.1186318,76.6870732 63.1199596,76.07 C63.11618,75.4552612 63.3831975,74.8700172 63.85,74.47 C64.3092247,74.0297195 64.9239099,73.7888779 65.56,73.8 C66.1987569,73.7905511 66.8159427,74.0309665 67.28,74.47 C67.7397907,74.874336 68.0023125,75.4577176 68.0000151,76.07 C68.0012886,76.6849379 67.7392008,77.2709952 67.28,77.68 Z M63.45,91.25 L63.45,79.33 L67.67,79.33 L67.67,91.25 L63.45,91.25 Z"></path>
                                                <path d="M85,74.57 L89.22,74.57 L89.22,91.25 L85,91.25 L85,90.16 C84.0643278,91.142598 82.7447356,91.6653173 81.39,91.59 C79.7811929,91.6249471 78.2343247,90.969803 77.14,89.79 C74.8390509,87.2311972 74.8390509,83.3488028 77.14,80.79 C78.2243966,79.6269846 79.7500373,78.976771 81.34,79 C82.7117991,78.9096512 84.0528063,79.4335967 85,80.43 L85,74.57 Z M80.4,87.18 C81.5158382,88.1176552 83.1441618,88.1176552 84.26,87.18 C85.247069,86.1171606 85.247069,84.4728394 84.26,83.41 C83.151877,82.4514048 81.508123,82.4514048 80.4,83.41 C79.412931,84.4728394 79.412931,86.1171606 80.4,87.18 L80.4,87.18 Z"></path>
                                                <path d="M101.33,77.68 C100.366941,78.5879949 98.863059,78.5879949 97.9,77.68 C97.4349409,77.2744125 97.1686318,76.6870732 97.1699596,76.07 C97.16618,75.4552612 97.4331975,74.8700172 97.9,74.47 C98.8688443,73.5766935 100.361156,73.5766935 101.33,74.47 C101.789791,74.874336 102.052312,75.4577176 102.050015,76.07 C102.051289,76.6849379 101.789201,77.2709952 101.33,77.68 L101.33,77.68 Z M97.5,91.25 L97.5,79.33 L101.72,79.33 L101.72,91.25 L97.5,91.25 Z"></path>
                                                <path d="M119,79.33 L123.22,79.33 L123.22,91.25 L119,91.25 L119,90.16 C118.065105,91.1437496 116.744958,91.6666889 115.39,91.59 C113.781193,91.6249471 112.234325,90.969803 111.14,89.79 C108.839051,87.2311972 108.839051,83.3488028 111.14,80.79 C112.234325,79.610197 113.781193,78.9550529 115.39,78.99 C116.746526,78.9158778 118.066918,79.4425718 119,80.43 L119,79.33 Z M114.4,87.18 C115.517566,88.1232697 117.152434,88.1232697 118.27,87.18 C119.24273,86.11157 119.24273,84.47843 118.27,83.41 C117.152434,82.4667303 115.517566,82.4667303 114.4,83.41 C113.42727,84.47843 113.42727,86.11157 114.4,87.18 L114.4,87.18 Z"></path>
                                                <path d="M139.31,79 C140.564722,78.9542357 141.781945,79.4324302 142.67,80.32 C143.603349,81.3569402 144.076067,82.7281822 143.98,84.12 L143.98,91.25 L139.76,91.25 L139.76,84.62 C139.797574,84.1000512 139.607885,83.5893496 139.24,83.22 C138.856997,82.8916868 138.364074,82.7202352 137.86,82.74 C137.291315,82.705682 136.73381,82.9084109 136.32,83.3 C135.932062,83.7434482 135.734459,84.3218852 135.77,84.91 L135.77,91.25 L131.56,91.25 L131.56,79.33 L135.77,79.33 L135.77,80.52 C136.63015,79.4645468 137.952357,78.8968197 139.31,79 Z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                        }

                    </div>
                    <NotMenuAdp>
                        <Menu/>
                        <Language/>
                    </NotMenuAdp>
                    <MenuAdp>
                        <MenuHamburger/>
                    </MenuAdp>
                </div>
            </header>
        </>

    );
};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(HeaderContainer);