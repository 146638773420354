import React from 'react';
import {graphql, StaticQuery} from "gatsby";
import {connect} from "react-redux";

import {ipApi} from '../../../api/ipApi';

import {setLanguages, selectLanguage, ipCountryLang} from "../../../redux/reducers/languageReducer";

import Language from '../components/Language';

const LanguageContainer = ({languagesList, languageSelect, setLanguages, selectLanguage, ipCountryLang, ipCountry}) => {
    return (
        <StaticQuery
            query={graphql`
          query {
            allContentfulLanguage {
                edges {
                  node {
                    id
                    languageID
                  }
                }
              }
          }
        `}
            render={data => {
                setLanguages(data.allContentfulLanguage.edges);

                !ipCountry && ipApi(ipCountryLang);

                return (
                    <Language elements={languagesList} languageSelect={languageSelect} selectLanguage={selectLanguage}/>
                )
            }}
        />
    );

};


let mapStateToProps = (state) => {
    return {
        languagesList: state.languageSelect["language-list"],
        languageSelect: state.languageSelect["languageSelect"],
        ipCountry: state.languageSelect.ipCountry,
    }
};

export default connect(mapStateToProps,
    {setLanguages, selectLanguage, ipCountryLang})(LanguageContainer);