import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";

import MenuItem from '../components/MenuItem';

const MenuContainer = ({languageSelect}) => {
    return (
        <StaticQuery
            query={graphql`
          query {
             contentfulHomeScreen {
                menu{
                    id
                    subtitle_ru
                    subtitle_en
                    path
                }
              }
          }
        `}
            render={data => {

                const menuItems = data.contentfulHomeScreen.menu.map(item => {
                    return (
                        <MenuItem key={item.id}
                                  name={item['subtitle_' + languageSelect]}
                                  path={item.path}
                        />
                    );
                });

                return (
                    <div className="menu">
                        {menuItems}
                    </div>
                )
            }}
        />

    );
};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(MenuContainer);