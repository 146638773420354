export { default as Layout } from './layout';
export { default as Seo } from './seo';
export { default as LayoutPages } from './layoutPages';
export { default as Preloader } from './Preloader/Preloader';
export { default as Breadcrumbs } from './Breadcrumbs/Breadcrumbs';

export { default as HeaderScrolling } from './HeaderScrolling/HeaderScrolling';
export { default as Modal } from './Modal/Modal';

export { default as SocialLinks } from './SocialLinks/SocialLinks';
export { default as TourDetail } from './TourDetail/TourDetail';