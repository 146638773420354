

let initialState = {
    "form-feedback": {
        "formLabelName_ru": 'Введите ваше имя:',
        "formLabelName_en": 'Enter your name:',

        "formPlaceholderName_ru": 'Как к вам обращаться?',
        "formPlaceholderName_en": 'What is your name?',


        "formLabelEmail_ru": 'Введите ваш email:',
        "formLabelEmail_en": 'Enter your email:',

        "formPlaceholderEmail_ru": 'Ваш email для связи',
        "formPlaceholderEmail_en": 'Your email for communication',


        "formLabelText_ru": 'Ваше сообщение:',
        "formLabelText_en": 'Your message:',

        "formPlaceholderText_ru": 'Введите сообщение',
        "formPlaceholderText_en": 'Enter your message',



        "button_ru": 'Отправить сообщение',
        "button_en": 'Send a message',

    },
    "consent_ru": 'Нажимая на кнопку \'Отправить сообщение’, вы даете согласие на обработку своих персональных данных',
    "consent_en": 'By clicking on the button \'Send message ’, you consent to the processing of your personal data',


    "errorLabel_ru": 'Обязательное поле',
    "errorLabel_en": 'Required field',


    "errorLabelName_ru": 'Поле содержит цифры',
    "errorLabelName_en": 'The field contains numbers',


    "errorLabelEmail_ru": 'Неверный адрес электронной почты',
    "errorLabelEmail_en": 'Invalid email address',


    "successSubtitle_ru": 'Спасибо',
    "successSubtitle_en": 'Thanks',


    "successMessage_ru": 'Мы свяжемся с вами в ближайшее время',
    "successMessage_en": 'We will contact you shortly',


    "successButtonClose_ru": 'Закрыть',
    "successButtonClose_en": 'Close',

};

const formReducer = (state = initialState) => {

    return state;

};



export default formReducer;