import React from 'react';


const SocialLinks = ({youtube, facebook, instagram}) => {
    return (
        <div className="social">
            {
                youtube &&
                <div className="social__item">
                    <a href={youtube} target="_blank" rel="noreferrer">
                        <svg width="21px" height="20px" viewBox="0 0 26 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" fill="none">
                                <g transform="translate(-1514.000000, -964.000000)" fill="#FFFFFF">
                                    <g>
                                        <g transform="translate(1514.000000, 964.000000)">
                                            <path d="M21.6,0 L4.4,0 C1.9699471,0 0,2.73603764 0,6.11111111 L0,18.8888889 C0,22.2639624 1.9699471,25 4.4,25 L21.6,25 C24.0300529,25 26,22.2639624 26,18.8888889 L26,6.11111111 C26,2.73603764 24.0300529,0 21.6,0 Z M14,16 L10,19 L10,7 L14,10 L18,13 L14,16 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
            }
            {
                facebook &&
                <div className="social__item">
                    <a href={facebook} target="_blank" rel="noreferrer">
                        <svg width="19px" height="20px" viewBox="0 0 14 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" fill="none">
                                <g transform="translate(-1590.000000, -965.000000)" fill="#FFFFFF">
                                    <g>
                                        <g transform="translate(1514.000000, 964.000000)">
                                            <g transform="translate(76.000000, 1.000000)">
                                                <path d="M13.4728805,0.00520167 L10.1149148,0 C6.34231768,0 3.90429932,2.41487 3.90429932,6.15253 L3.90429932,8.98926 L0.528010299,8.98926 C0.236256021,8.98926 0,9.21762 0,9.49929 L0,13.6094 C0,13.8911 0.236525329,14.1191 0.528010299,14.1191 L3.90429932,14.1191 L3.90429932,24.4902 C3.90429932,24.7719 4.14055534,25 4.43229926,25 L8.83735693,25 C9.12914228,25 9.36540866,24.7716 9.36540866,24.4902 L9.36540866,14.1191 L13.3130563,14.1191 C13.6048416,14.1191 13.841108,13.8911 13.841108,13.6094 L13.8426617,9.49929 C13.8426617,9.36404 13.7869356,9.23452 13.6881201,9.13881 C13.589201,9.0431 13.4545468,8.98926 13.3144028,8.98926 L9.36540866,8.98926 L9.36540866,6.58453 C9.36540866,5.42872 9.65066846,4.84197 11.2101716,4.84197 L13.472259,4.84119 C13.7637336,4.84119 14,4.61284 14,4.33143 L14,0.514965 C14,0.233815 13.7640444,0.00572184 13.4728805,0.00520167 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
            }
            {
                instagram &&
                <div className="social__item">
                    <a href={instagram} target="_blank" rel="noreferrer">
                        <svg width="20px" height="20px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="none" fill="none">
                                <g transform="translate(-1654.000000, -965.000000)" fill="#FFFFFF">
                                    <g>
                                        <g transform="translate(1514.000000, 964.000000)">
                                            <g transform="translate(140.000000, 1.000000)">
                                                <path d="M18.1007559,0 L6.89894831,0 C3.09487052,0 0,3.0950184 0,6.89909619 L0,18.1009038 C0,21.9051295 3.09487052,25 6.89894831,25 L18.1007559,25 C21.9051295,25 25,21.9049816 25,18.1009038 L25,6.89909619 C25,3.0950184 21.9051295,0 18.1007559,0 Z M22.7820267,18.1009038 C22.7820267,20.6820575 20.6820575,22.7818788 18.1009038,22.7818788 L6.89894831,22.7818788 C4.31794253,22.7818788 2.21812116,20.6820575 2.21812116,18.1009038 L2.21812116,6.89909619 C2.21812116,4.3180904 4.31794253,2.21812116 6.89894831,2.21812116 L18.1007559,2.21812116 C20.6819096,2.21812116 22.7820267,4.3180904 22.7820267,6.89909619 L22.7820267,18.1009038 L22.7820267,18.1009038 Z" id="Shape"></path>
                                                <path d="M12.5,6 C8.91584602,6 6,8.91591295 6,12.5001492 C6,16.0842363 8.91584602,19 12.5,19 C16.084154,19 19,16.0842363 19,12.5001492 C19,8.91591295 16.084154,6 12.5,6 Z M12.5,16.7616615 C10.1501044,16.7616615 8.23813787,14.8499495 8.23813787,12.5 C8.23813787,10.1499013 10.1499552,8.23804003 12.5,8.23804003 C14.8500448,8.23804003 16.7618621,10.1499013 16.7618621,12.5 C16.7618621,14.8499495 14.8498956,16.7616615 12.5,16.7616615 Z" id="Shape"></path>
                                                <path d="M19,4 C18.4745455,4 17.9583636,4.21272727 17.5872727,4.58545455 C17.2143636,4.95636364 17,5.47272727 17,6 C17,6.52563636 17.2145455,7.04181818 17.5872727,7.41454545 C17.9581818,7.78545455 18.4745455,8 19,8 C19.5272727,8 20.0418182,7.78545455 20.4145455,7.41454545 C20.7872727,7.04181818 21,6.52545455 21,6 C21,5.47272727 20.7872727,4.95636364 20.4145455,4.58545455 C20.0436364,4.21272727 19.5272727,4 19,4 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
            }

        </div>
    );
}

export default SocialLinks;