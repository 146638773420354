import React from 'react';


import PhotoGalleryItem from '../components/PhotoGalleryItem';


const PhotoGalleryDetail = ({items}) => {
    return (
        <PhotoGalleryItem items={items}/>
    )
};


export default PhotoGalleryDetail;