import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {LayoutPages} from "../components";
import "../scss/index.scss";



const PersonalDataPage = ({languageSelect}) => {


    return (
        <StaticQuery
            query={graphql`
              query  {
                contentfulPersonalData {
                    subtitle_ru
                    subtitle_en
                    description_ru{
                        json
                    }
                    description_en{
                        json
                    }
                }
              }
            `}
            render={data => {
                const breadcrumbs = [
                    {
                        pathname: "/", crumbLabel: `${languageSelect === 'ru' ? 'Главная' : languageSelect === 'en' ? 'Home' : '首頁'}`, active: false
                    },
                    {
                        pathname: "", crumbLabel: `${data.contentfulPersonalData["subtitle_" + languageSelect]}`, active: true
                    }
                ];

                return (
                    <LayoutPages titlePage={data.contentfulPersonalData["subtitle_" + languageSelect]}
                                 breadcrumbs={breadcrumbs}
                    >
                        <section className="section">
                            <div className="container">
                                <div className="section-about__text">
                                    {documentToReactComponents(data.contentfulPersonalData['description_' + languageSelect].json)}
                                </div>
                            </div>
                        </section>
                    </LayoutPages>
                );
            }}
        />

    );
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(PersonalDataPage);