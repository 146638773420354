import React, {useEffect} from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';



const Modal = ({title, isOpen, onCancel, onSubmit, children, successButtonClose}) => {

    const modal = React.createRef();
    const modalWrapper = React.createRef();

    function handleClickOutside(event) {
        if (modal.current && !modalWrapper.current.contains(event.target) && isOpen) {
            onCancel ? onCancel() : onSubmit();
        }
    }
    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    return (
        <div className={classNames("modal", {
            'modal_active': isOpen
        })} ref={modal}>
            <div className="modal-wrapper" ref={modalWrapper}>
                {
                    onCancel && <div className="modal-cancel" onClick={onCancel}></div>
                }

                <div className="modal-head">
                    {title}
                </div>
                {children}
                {
                    onSubmit &&
                        <div className="modal-button">
                            <button className="button" onClick={onSubmit}>
                                <span className="button-detail-link-hover button-detail-link-hover_bottom"></span>
                                <span className="button-detail-link-hover button-detail-link-hover_top"></span>
                                {successButtonClose}
                            </button>
                        </div>
                }
            </div>
        </div>
    );
};



let mapStateToProps = (state) => {
    return {
        successButtonClose: state.form['successButtonClose_' + state.languageSelect["languageSelect"]],
    }
};

export default connect(mapStateToProps)(Modal);