import React from 'react';
import {Link} from "gatsby";


const MenuItem = ({name, path}) => {
    return (
        <Link to={path} className="menu__link" activeClassName="menu__link_active">{name}</Link>
    );
};


export default MenuItem;