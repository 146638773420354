import React from 'react';
import {combineReducers, createStore} from "redux";


import languageReducer from "./reducers/languageReducer";
import formReducer from "./reducers/formReducer";
import menuHamburger from "./reducers/menuHamburger";




let reducers = combineReducers({
    languageSelect: languageReducer,
    menuHamburger: menuHamburger,
    form: formReducer,
});

let store = createStore(reducers);

export default store;
