import React, {useState} from 'react';
import PropTypes from 'prop-types';
import FsLightbox from 'fslightbox-react';
import Img from "gatsby-image";
import classNames from 'classnames';



const PhotoGalleryItem = ({items, className}) => {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    const openLightboxOnSlide = (number) => {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    };

    const photoLightbox = items.map(item => {
        return item.img.fluid.src;

    });

    const photoItems = items.map((item, key) => {
        return (
            <div key={item.id}
                 className="photo-gallery-item"
                 onClick={() => openLightboxOnSlide(key + 1)}
            >
                <Img fluid={item.img.fluid}
                     alt={item.img.title}
                     className='photo-gallery-item__img'
                />
            </div>
        )
    });

    return (
        <div className={classNames("photo-gallery", className, {
            'photo-gallery_grid_one': photoLightbox.length === 1,
            'photo-gallery_grid_two': photoLightbox.length === 2,
            'photo-gallery_grid_three': photoLightbox.length === 3,
            'photo-gallery_grid_four': photoLightbox.length === 4,
            'photo-gallery_grid_many': photoLightbox.length >= 7,
        })}>
            {photoItems}
            <FsLightbox
                toggler={ lightboxController.toggler }
                sources={ photoLightbox }
                slide={ lightboxController.slide }
            />
        </div>
    );
};


export default PhotoGalleryItem;

PhotoGalleryItem.propTypes = {
    img: PropTypes.object,
    imgAlt: PropTypes.string,
};