
const SELECT_LANGUAGE = "SELECT-LANGUAGE";
const SET_LANGUAGES = "SET-LANGUAGES";
const IP_COUNTRY_LANG = "IP-COUNTRY-LANG";


let initialState = {
    "language-list": [],
    "languageSelect": 'ru',
    "ipCountry": false
};

const languageReducer = (state = initialState, action) => {

    switch (action.type) {
        case SELECT_LANGUAGE:
            return {
                ...state,
                ["language-list"]: state["language-list"].map( (items) => {
                    if (action.languageCode === items.node.languageID) {
                        state.languageSelect = items.node.languageID;
                    }
                    return {...items}
                }),
            };
        case SET_LANGUAGES:
            return {
                ...state,
                ["language-list"]: action.languages
            };
        case IP_COUNTRY_LANG:
            if (action.country !== 'RU') {
                return {
                    ...state,
                    ["languageSelect"]: 'en',
                    "ipCountry": true
                };
            }
            else {
                return {
                    ...state,
                    ["languageSelect"]: 'ru',
                    "ipCountry": true
                };
            }
        default:
            return state;
    };

};


export const selectLanguage = (code) => ({type: SELECT_LANGUAGE, languageCode: code});
export const setLanguages = (languages) => ({type: SET_LANGUAGES, languages: languages});
export const ipCountryLang = (country) => ({type: IP_COUNTRY_LANG, country: country});

export default languageReducer;