/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';


import {Breadcrumbs} from '../components';

import Header from '../modules/Header/containers/HeaderContainer';
import Footer from '../modules/Footer/containers/Footer';
import HeaderScrolling from "./HeaderScrolling/HeaderScrolling";

const LayoutPages = ({children, titlePage, breadcrumbs, buttonDoc, buttonDocName, className, contacts}) => {

    return (


        <>
            <Header className="header-page"/>
            <HeaderScrolling/>
            <div className={classNames("section-page", className)}>
                <div className="container">
                    {
                        breadcrumbs &&
                            <Breadcrumbs items={breadcrumbs}/>
                    }
                    <div className="section-head">
                        <h1 className="subtitle subtitle_lvl_one subtitle_color_black section-page__subtitle">
                            {titlePage}
                        </h1>
                        {
                            buttonDoc &&
                            <div className="slider-buttons">

                                <button className="button button-download">

                                    <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g transform="translate(-569.000000, -302.000000)" fill="#186EFF" fillRule="nonzero">
                                                <g transform="translate(569.000000, 302.000000)">
                                                    <path d="M29,21.3402367 L29,27.5798817 C29,27.704142 28.981738,27.8195266 28.9543451,27.9260355 L28.9543451,27.9260355 C28.9543451,27.9260355 28.9543451,27.9260355 28.9543451,27.9260355 C28.8265113,28.5384615 28.2695214,29 27.6120907,29 L1.37877834,29 C0.675692695,29 0.0913098237,28.4763314 0.0182619647,27.8106509 C0,27.7307692 0,27.6597633 0,27.5710059 L0,21.3313609 C0,20.5946746 0.620906801,20 1.36964736,20 C1.74401763,20 2.09099496,20.1508876 2.33753149,20.3905325 C2.58406801,20.6301775 2.73929471,20.9674556 2.73929471,21.3313609 L2.73929471,26.3284024 L26.2698363,26.3284024 L26.2698363,21.3313609 C26.2698363,20.5946746 26.8907431,20 27.6394836,20 C28.0138539,20 28.3608312,20.1508876 28.6073678,20.3905325 C28.8447733,20.6390533 29,20.9763314 29,21.3402367 Z"></path>
                                                    <path d="M22.523047,14.7248434 L15.6817629,21.4947808 C15.6724803,21.5131524 15.6539151,21.5223382 15.6446325,21.531524 C15.394002,21.7795407 15.0691106,21.9356994 14.7442192,21.9816284 C14.7163714,21.9816284 14.6885236,21.9908142 14.6606758,21.9908142 C14.6049801,22 14.5492844,22 14.4935888,22 L14.4935888,22 L14.3357844,21.9908142 C14.3079365,21.9908142 14.2800887,21.9816284 14.2522409,21.9816284 C13.9180669,21.9356994 13.6024581,21.7795407 13.3518276,21.531524 C13.342545,21.5223382 13.3239798,21.5039666 13.3146972,21.4947808 L6.47341314,14.7248434 C6.15780438,14.4125261 6,13.9991649 6,13.5858038 C6,13.1724426 6.15780438,12.7590814 6.47341314,12.4467641 C7.10463067,11.8221294 8.13500045,11.8221294 8.77550059,12.4467641 L12.8876971,16.5160752 L12.8876971,1.60751566 C12.8876971,0.725678497 13.6210234,0 14.512154,0 C14.9577193,0 15.3661542,0.183716075 15.6631977,0.468475992 C15.9602412,0.762421712 16.1366108,1.15741127 16.1366108,1.60751566 L16.1366108,16.5160752 L20.2488074,12.4467641 C20.8800249,11.8221294 21.9103947,11.8221294 22.5508948,12.4467641 C23.1542645,13.0805846 23.1542645,14.1002088 22.523047,14.7248434 Z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <a href={buttonDoc} className="button-download__link">
                                       {buttonDocName}
                                    </a>
                                </button>

                            </div>
                        }
                        {
                            contacts &&
                            <div className="section-head-contacts">
                                <svg width="26px" height="22px" viewBox="0 0 26 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1232.000000, -4356.000000)" fill="#186EFF" fillRule="nonzero">
                                            <path d="M1257.99623,4367.00082 C1258.00126,4366.94293 1258.00126,4366.88472 1257.99623,4366.82683 C1257.99623,4366.81883 1257.99623,4366.81183 1257.99623,4366.80383 C1257.98337,4366.76214 1257.96766,4366.72138 1257.94921,4366.68184 C1257.93214,4366.62149 1257.90937,4366.56289 1257.88118,4366.50686 C1257.81543,4366.4166 1257.74462,4366.33013 1257.66909,4366.24788 C1257.61968,4366.20963 1257.56675,4366.17615 1257.51102,4366.14788 C1257.47598,4366.12207 1257.43921,4366.09869 1257.40097,4366.07789 L1233.39052,4356.07864 C1233.01131,4355.91877 1232.57284,4356.00733 1232.28549,4356.30182 C1231.99814,4356.59631 1231.92055,4357.03665 1232.08996,4357.41154 L1236.40183,4367.00082 L1232.08796,4376.5901 C1231.94881,4376.8995 1231.97608,4377.2583 1232.16041,4377.54313 C1232.34473,4377.82796 1232.66096,4378 1233.00035,4378 C1233.1326,4378.00029 1233.26356,4377.97411 1233.38552,4377.923 L1257.39597,4367.92375 C1257.43421,4367.90295 1257.47098,4367.87956 1257.50602,4367.85375 C1257.56175,4367.82548 1257.61467,4367.792 1257.66409,4367.75376 C1257.80153,4367.64262 1257.89999,4367.49062 1257.94521,4367.31979 C1257.96365,4367.28025 1257.97936,4367.23949 1257.99223,4367.1978 C1257.99223,4367.1898 1257.99223,4367.18281 1257.99223,4367.17481 C1257.99859,4367.11703 1258,4367.05882 1257.99623,4367.00082 Z M1252.00863,4366.00089 L1238.14859,4366.00089 L1234.9472,4358.90143 L1252.00863,4366.00089 Z M1234.9502,4375.10021 L1238.15159,4368.00074 L1252.00863,4368.00074 L1234.9502,4375.10021 Z" id="Shape"></path>
                                        </g>
                                    </g>
                                </svg>
                                <a href={'tel:' + contacts.phone} className="section-head-contacts__link">
                                    {contacts.phone}
                                </a>
                                /
                                <a href={'mailto:' + contacts.email} className="section-head-contacts__link">
                                    {contacts.email}
                                </a>

                            </div>
                        }

                    </div>
                </div>
                {children}
            </div>
            <Footer/>

        </>
    )
};

LayoutPages.propTypes = {
    children: PropTypes.node.isRequired,
    titlePage: PropTypes.string,
    titleDesc: PropTypes.string,
};

export default LayoutPages;
