import React from 'react';
import { Link } from "gatsby";


const Breadcrumbs = ({items}) => {
    const breadcrumbs = items.map(item => {
        return (

            <div className="breadcrumbs__item" key={item.crumbLabel}>
                {
                    !item.active ?
                        <>
                            <Link to={item.pathname} className="breadcrumbs__link">{item.crumbLabel}</Link>
                            <span> > </span>
                        </>
                        :
                        <div className="breadcrumbs__name breadcrumbs__name_active">{item.crumbLabel}</div>
                }
            </div>

        );
    })
    return (
        <div className="breadcrumbs">
            {breadcrumbs}
        </div>
    );
};


export default Breadcrumbs;