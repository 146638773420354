import React from 'react';
import classNames from "classnames";



const Language = ({className, elements, languageSelect, selectLanguage}) => {

    const languageElements = elements.map(item => {
        return (
            <div className={classNames('language__item', className, {
                "language__item_active": item.node.languageID === languageSelect
            })}
                 key={item.node.id}
                 onClick={() =>{
                     selectLanguage(item.node.languageID)
                 }}
            >
                {item.node.languageID}
            </div>
        );
    });

    return (
        <div className="language">
            {languageElements}
        </div>
    );
};


export default Language;