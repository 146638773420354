import React, {useEffect} from 'react';

const Preloader = () => {

    return (
        <div className="preloader">
            <div className="preloader__item"></div>
        </div>
    )
};

export default Preloader;