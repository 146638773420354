const OPEN_MENU = "OPEN-MENU";

let initialState = {
    "isOpen": false
};

const menuHamburger = (state = initialState, action) => {

    switch (action.type) {
        case OPEN_MENU:
            return {
              ...state,
                "isOpen": action.status
            };
        default:
            return state;
    };


};


export const isMenuOpen = (status) => ({type: OPEN_MENU, status: status});


export default menuHamburger;