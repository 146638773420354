import React from "react";
import {connect} from "react-redux";
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import {graphql, StaticQuery} from "gatsby";
import "../scss/index.scss";

import {Layout, Preloader, Seo} from "../components";


const SectionMain = loadable(() => pMinDelay(import('../modules/Main/containers/MainContainer'), 1000), {
    fallback: <Preloader/>,
});
const SectionTours = loadable(() => pMinDelay(import('../modules/Tours/containers/TourHomeContainer'), 1000));
const SectionAbout = loadable(() => pMinDelay(import('../modules/About/containers/AboutHome'), 1000));
const SectionReviews = loadable(() => pMinDelay(import('../modules/Reviews/containers/ReviewsContainer'), 1000));
const SectionPhoto = loadable(() => pMinDelay(import('../modules/PhotoGallery/containers/PhotoGalleryHome'), 1000));
const SectionFeedback = loadable(() => pMinDelay(import('../modules/FeedbackForm/containers/FeedbackForm'), 1000));


const IndexPage = ({languageSelect}) => {

    return (
        <StaticQuery
            query={graphql`
              query  {
                contentfulHomeScreen {
                    id
                    seo {
                      title_ru
                      title_en
                      description_ru
                      description_en
                      keywords_ru
                      keywords_en
                    }
                  }
              }
            `}
            render={data => {



                return (
                    <Layout>
                        <Seo title={`${data.contentfulHomeScreen.seo["title_" + languageSelect]}`}
                             description={`${data.contentfulHomeScreen.seo["description_" + languageSelect]}`}
                             keywords={`${data.contentfulHomeScreen.seo["keywords_" + languageSelect]}`}
                        />
                        <SectionMain/>
                        <SectionTours/>
                        <SectionAbout/>
                        <SectionReviews/>
                        <SectionPhoto/>
                        <SectionFeedback/>
                    </Layout>
                );
            }}
        />


    )

};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(IndexPage);
