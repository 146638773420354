import React, {useState} from 'react';
import classNames from 'classnames';
import Responsive from "react-responsive";

import Language from '../../modules/Language/containers/LunguageContainer';
import Menu from '../../modules/Menu/containers/MenuContainer';
import MenuHamburger from '../../modules/Menu/containers/MenuHamburgerContainer';


const MenuAdp = props => <Responsive {...props} maxWidth={1023}/>;
const NotMenuAdp = props => <Responsive {...props} minWidth={1024}/>;


const HeaderScrolling = () => {

    let headerFixedMenu = React.createRef();

    const [fixedMenu, setFixedMenu] = useState(false);
    const [top, setTop] = useState(0);
    const [lastScroll, setLastScroll] = useState(300);

    if (typeof window !== 'undefined') {

        window.onscroll = () => {
            setTop(window.pageYOffset);

            if (lastScroll > top && top > 400) {
                setFixedMenu(true);
            } else if (lastScroll < top) {
                setFixedMenu(false);

            } else {
                setFixedMenu(false);
            }
            setLastScroll(top);
        };
    }

    return (

        <div className={classNames('header-menu-fixed', {
            'header-menu-fixed_active': fixedMenu
        })}
             ref={headerFixedMenu}
        >
            <div className="container">
                <NotMenuAdp>
                    <Menu/>
                    <Language/>
                </NotMenuAdp>
                <MenuAdp>
                    <MenuHamburger/>
                </MenuAdp>

            </div>
        </div>
    );

};


export default HeaderScrolling;