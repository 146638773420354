import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";

import Footer from '../components/Footer';




const FooterContainer = ({languageSelect}) => {
    return (
        <StaticQuery
            query={graphql`
          query {
            contentfulContacts {
                instagram
                facebook
                youtube
              }
              sectionAbout: file(relativePath: { eq: "footer-bg.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
                }
          }
        `}
            render={data => {

                return (
                    <Footer imgBg={data.sectionAbout.childImageSharp.fluid}
                            facebook={data.contentfulContacts.facebook}
                           /* instagram={data.contentfulContacts.instagram}
                            youtube={data.contentfulContacts.youtube}*/
                            copyrightName={languageSelect === 'ru' ? '135 меридиан' : '135 meridian'}
                            personalText={languageSelect === 'ru' ? 'Согласие на обработку персональных данных' : 'Processing personal data'}
                    />
                )
            }}
        />
    )
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(FooterContainer);