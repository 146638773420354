import React, { useState, useRef, useEffect } from "react";
import {connect} from "react-redux";
import { YMaps, Placemark, Polyline, Map, ZoomControl } from "react-yandex-maps";


const YandexMapContainer = ({languageSelect, yandexRoutes}) => {

    const center = [parseFloat(yandexRoutes[1].split(', ')[0]), parseFloat(yandexRoutes[1].split(', ')[1])];
    const mapState = { center, zoom: 5 };

    const [mapApi, setMapApi] = useState(null);
    const [lang, setLang] = React.useState("ru_RU");

    const polyline = useRef(null);
    const map = useRef(null);

    useEffect(() => {
        languageSelect === 'en' ? setLang("en_US") : setLang("ru_RU")
    });

    const mapPolygone = [yandexRoutes.map(item => {
        let mapRoutes = item.split(', ');
        let route = [parseFloat(mapRoutes[0]), parseFloat(mapRoutes[1])];

        return route;
    })];

    const handleApiAvaliable = ymaps => {
        setMapApi(ymaps);
    };

    return (
            <YMaps key={lang} query={{ lang }}>
                <Map

                    state={mapState}
                    onLoad={ymaps => handleApiAvaliable(ymaps)}
                    /*instanceRef={map}*/
                    instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }}
                    className="yandex-map"
                >
                    <ZoomControl options={{ float: 'right' }} />
                    {
                        yandexRoutes.map((coordinates, index) => {

                            let mapRoutes = coordinates.split(', ');
                            let route = [parseFloat(mapRoutes[0]), parseFloat(mapRoutes[1])];

                            return (
                                <Placemark
                                    key = {index}
                                    geometry={route}
                                    modules={["geoObject.addon.hint"]}
                                    options={{
                                        preset: 'islands#blueCircleDotIcon'
                                    }}
                                    properties={{
                                        hintContent: "1 Собственный значок метки",
                                        id: "123"
                                    }}
                                />
                            );
                        })
                    }


                    <Polyline
                        geometry={mapPolygone[0]}
                        instanceRef={polyline}
                        options={{
                            balloonCloseButton: false,
                            strokeColor: "#FFEC72",
                            strokeWidth: 4,
                            strokeOpacity: 1
                        }}
                    />

                </Map>
            </YMaps>

    );
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(YandexMapContainer);