import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import "../scss/index.scss";

import {LayoutPages, Preloader, Seo} from "../components";

const Tour = loadable(() => pMinDelay(import('../modules/Tours/containers/TourContainer'), 1000), {
    fallback: <Preloader/>,
});


const TourPage = ({languageSelect}) => {
    return (
        <StaticQuery
            query={graphql`
          query {
             contentfulTour {
                subtitle_ru
                subtitle_en
                seo {
                      title_ru
                      title_en
                      description_ru
                      description_en
                      keywords_ru
                      keywords_en
                    }
              }
          }
        `}
            render={data => {
                const breadcrumbs = [
                    {
                        pathname: "/", crumbLabel: `${languageSelect === 'ru' ? 'Главная' : 'Home'}`, active: false
                    },
                    {
                        pathname: "", crumbLabel: `${data.contentfulTour["subtitle_" + languageSelect]}`, active: true
                    }
                ];
                return (
                    <LayoutPages titlePage={data.contentfulTour["subtitle_" + languageSelect]}
                                 breadcrumbs={breadcrumbs}
                    >
                        <Seo title={`${data.contentfulTour.seo["title_" + languageSelect]}`}
                             description={`${data.contentfulTour.seo["description_" + languageSelect]}`}
                             keywords={`${data.contentfulTour.seo["keywords_" + languageSelect]}`}
                        />
                        <Tour/>
                    </LayoutPages>
                )
            }}
        />

    );
};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(TourPage);